import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import ChineseLayout from "../../../components/ChineseLayout"
// import Inactivity from "../../../components/Inactivity"

/* Farms page, uses the Video > Rich Text > Stepped Navigation structure */

class Farms extends React.Component {
  componentDidMount() {
    // Inactivity()
  }
  render() {
    return(
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - Visit One Of Our Farms - Family</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -general -video">
            <div className="video-player-wrapper">
              <div className="youtube-player-wrapper">
                <iframe className="youtube-player" id="youtube-video" title="video" width="540" height="304" src="https://www.youtube.com/embed/KiZB95eGfK0?controls=0&modestbranding=1&playsinline=1rel=0" allow="autoplay" frameBorder="0"></iframe>
              </div>
            </div>
          </section>

          <section className="rich-text -bg-white -with-stepped">
            <nav className="breadcrumb" role="navigation">
              <Link to="/zh/event-experience" title="活动体验"> 
                <i className="fas fa-arrow-left"></i>
                活动体验
              </Link>
            </nav>
            <h2>牧场家庭生活</h2>
            <p>这个家庭牧场是我和家人共同协作的地方之一。这不仅是简单的团队工作，更需要我们对所做的工作承担重大的责任，因为我们知道我们肩负着是生产农作物的责任。是以牧场为基础，生产高品质、好营养的牛奶，为了需要我们的消费者。我们对此感到非常自豪，也对此非常重视，因为我们掌握这些知识，将这些技能和价值观传递给我们的孩子们，让他们迎接更加美好的未来。</p>
          </section>

          <ol className="stepped-navigation">
            <li className="stepped-navigation__item"><Link to="/zh/event-experience/grazing"></Link></li>
            <li className="stepped-navigation__item -active"><span>家庭牧场</span></li>
            <li className="stepped-navigation__item"><Link to="/zh/event-experience/milk"></Link></li>
          </ol>
        </main>
      </ChineseLayout>
    )
  }
}

export default Farms